import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference, DocumentChangeAction, QueryFn } from '@angular/fire/firestore';



import { AuthService } from './auth.service';



@Injectable({
    providedIn: 'root'
})
export class AngFireStoreService {

    constructor(private afs: AngularFirestore, private auth: AuthService) {

    }

    getAngDoc<TYPE>(collection: string, id: string): AngularFirestoreDocument<TYPE> {


        return this.afs.collection(collection).doc(id);


    }

    getAfs() {
        return this.afs;
    }

    getCollection<TYPE>(path: string, qryFcn?: QueryFn): AngularFirestoreCollection<TYPE> {
        if (qryFcn) {
            return this.afs.collection(path, qryFcn);

        } else {
            return this.afs.collection(path);
        }
    }

    addToCollection(data: any, collectionName: string) {
        const promise = new Promise((resolve, reject) => {
            this.afs.collection(collectionName).ref.add(data)
                .then((res) => {
                    console.log('dataService: createOne success: res: ', res);
                    resolve(res);
                }).catch(err => {
                    console.error('dataService: createOne: error: ', err);
                    reject(err);
                });
        });

        return promise;
    }

    removeFromCollection(key: string, collectionName: string) {
        this.afs.collection(collectionName).doc(key).delete().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }

}