import { PushService } from './core/services/push.service';
import { SubJobListComponent } from './sub-job-list/sub-job-list.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngFireStoreService } from './core/services/afs.service';
import { MainService } from './core/services/main.service';
import { PasswordStrengthBar } from './login/passwordStrengthBar';
import { FormsModule } from '@angular/forms';
// import { ContactPage } from './contact/contact.page';
// import { AboutPage } from './about/about.page';
// import { HomePage } from './home/home.page';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPage } from './login/login.page';
import { TabsPage } from './tabs/tabs.page';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee, faHome, faSignInAlt, faSignOutAlt, faThumbsUp, faThumbsDown, faBan, faPlusCircle, faCalendarAlt, faEdit, faCog, faUserPlus, faTrash, faUserMinus, faUserEdit, faUserFriends, faMale, faFemale, faTimesCircle, faFile, faList } from '@fortawesome/free-solid-svg-icons';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { FCM } from '@ionic-native/fcm/ngx';
import { AdminGuard } from './core/admin.guard';
import { SubService } from './core/services/sub.service';
import { JobDetailComponent } from './job-detail/job-detail.component';
import { MyJobsComponent } from './my-jobs/my-jobs.component';
import { Device } from '@ionic-native/device/ngx';
import { ProfileComponent } from './profile/profile.component';

library.add(faCoffee, faHome, faSignInAlt, faSignOutAlt, faThumbsUp, faThumbsDown, faBan, faPlusCircle, faCalendarAlt, faEdit, faCog, faUserPlus, faTrash, faUserMinus, faUserEdit, faUserFriends, faMale, faFemale, faTimesCircle, faFile, faList);


@NgModule({
  declarations: [AppComponent,
    PasswordStrengthBar,
    LoginPage,
    // HomePage,
    TabsPage,
    // AboutPage,
    // ContactPage,
    SubJobListComponent,
    JobDetailComponent,
    MyJobsComponent,
    ProfileComponent],
  entryComponents: [LoginPage,
    // HomePage,
    TabsPage,
    // AboutPage,
    // ContactPage,
    SubJobListComponent,
    JobDetailComponent,
    MyJobsComponent],
  imports: [BrowserModule, FormsModule, FontAwesomeModule, AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    IonicModule.forRoot()],
  providers: [
    StatusBar,
    MainService,
    PushService,
    AngFireStoreService,
    FCM,
    Device,
    AngularFireAuth, AngularFirestore, AdminGuard, SubService,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  private appVersion: number = 1;
  private appName: string = 'subs';


  constructor(private ps: PushService, private ms: MainService) {
    console.log(this.ps.someData);
    this.ms.checkVersion(this.appName, this.appVersion);
  }

}


