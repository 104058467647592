import { AuthService } from './../core/services/auth.service';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { MainService } from '../core/services/main.service';
import { eVersionStatus } from '../core/interfaces/eVersionStatus';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage {

  private versionData: { must: eVersionStatus, should: eVersionStatus, newer: eVersionStatus };
  versionSub: Subscription;
  constructor(private router: Router, private mainService: MainService, private authService: AuthService) {
    this.versionSub = this.mainService.versionBehSubj.subscribe((versionData) => {
      this.versionData = versionData;
    })
  }
  gotoPage(page: string) {
    this.router.navigate(['/tabs/' + page]);
  }



  signedIn(): boolean {
    return this.authService.signedIn
  }

  getVersionText() {
    if (this.versionData.must == eVersionStatus.true) {
      return 'There is a new version of this app.  You must update or your app will fail!';
    } else if (this.versionData.should == eVersionStatus.true) {
      return 'There is a new version of this app.  You should consider updating this app.';

    } else if (this.versionData.newer == eVersionStatus.true) {
      return 'There is a new version of this app.';

    } else if (this.versionData.newer == eVersionStatus.checking) {
      return 'Checking your current app version...';
    } else {
      return 'App version is up to date.'
    }
  }


  getVersionBGColor() {
    if (this.versionData.must == eVersionStatus.true) {
      return 'red';
    } else if (this.versionData.should == eVersionStatus.true) {
      return 'orange';

    } else if (this.versionData.newer == eVersionStatus.true) {
      return 'steelblue';

    } else if (this.versionData.newer == eVersionStatus.checking) {
      return 'tan';
    } else {
      return 'honeydew'
    }
  }

  shouldUpdate() {
    return this.versionData.should;
  }



  mustUpdate() {
    return this.versionData.must;
  }


  newVersion() {
    return this.versionData.newer;
  }
}
