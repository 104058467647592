import { ISchoolUserCross } from './../interfaces/ISchoolUserCross';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ISchoolUser } from "../interfaces";




export interface IRegister { email: string, pw: string, pw2: string, fName: string, lName: string, phone: number, school: string }


@Injectable({ providedIn: 'root' })
export class AuthService {

  user: Observable<ISchoolUser>;
  signedIn: boolean = false;
  isUser = new BehaviorSubject<boolean>(false);

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {

    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          console.log('auth ligged in: ', user);
          this.signedIn = true;
          if (user.emailVerified == true) {
            this.isUser.next(true)
          }
          return this.afs.doc<ISchoolUser>(`users/${user.uid}`).valueChanges()
        } else {
          this.signedIn = false;
          return of(null)
        }
      })
    )
  }



  isLoggedIn() {
    // return this.http.get(ApiResources.LOGON).map(response => response.ok);

    // let getLogin = new Promise((resolve, reject) => {


    //   if (/* condition */) {
    //     resolve(/* value */);  // fulfilled successfully
    //   }
    //   else {
    //     reject(/* reason */);  // error, rejected
    //   }
    // });
    this.user.subscribe(userData => {
      if (userData) {
        return true;
      } else {
        return false
      }

    })
  }







  googleLogin() {
    const provider = new auth.GoogleAuthProvider()
    return this.oAuthLogin(provider);
  }
  errorCode: string;
  errorMsg: string;

  createAccount(data: IRegister) {


    this.afAuth.auth.createUserWithEmailAndPassword(data.email, data.pw).then((credential) => {
      credential.user.sendEmailVerification()
      this.updateUserData(credential.user, data)
    }).catch((error) => {
      console.log(error.code);
      this.errorCode = error.code;
      this.errorMsg = error.message;
      console.log(error.message);
    });
  }
  returnPage: string;

  signInEMail(email: string, pw: string, returnPage: string = undefined): Promise<number> {
    let thePromise = new Promise<number>((res, rej) => {

      this.afAuth.auth.signInWithEmailAndPassword(email, pw).then((credential) => {
        console.log('auth ligged in: ', credential.user);
        this.returnPage = returnPage
        this.updateUserData(credential.user);
        res(1)
      }).catch((error) => {
        // Handle Errors here.
        this.errorCode = error.code;
        this.errorMsg = error.message;
        if (error.code == 'auth/wrong-password') {
          res(2);
        } else if (error.code == 'auth/weak-password') {
          res(3)
        }
        // ...
      });



    })
    return thePromise;

  }


  async emailAlready(theEmail) {
    return this.afAuth.auth.fetchProvidersForEmail(theEmail);
  }


  getCurrentUser() {
    return this.afAuth.auth.currentUser;
  }

  signInOnly(email: string, password: string): Promise<auth.UserCredential> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }
  emailVerified = false;

  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user)
      })
  }
  reSendVerification() {
    this.afAuth.auth.currentUser.sendEmailVerification();
  }
  resetPW(email: string) {
    this.afAuth.auth.sendPasswordResetEmail(email);
  }
  IVerified() {
    this.updateUserData(this.afAuth.auth.currentUser);
  }

  private updateUserData(user: firebase.User, extraData?: IRegister) {
    console.log('UserSet Auth')
    // Sets user data to firestore on login
    if (user.emailVerified) {
      console.log('email is verified');
      this.emailVerified = true;

    };
    this.signedIn = true;
    this.afAuth.auth.setPersistence('local');
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    let data: ISchoolUser;
    if (extraData) {
      data = {
        uid: user.uid,
        email: user.email,
        isVerified: user.emailVerified,
        uFName: extraData.fName,
        displayName: extraData.fName + ' ' + extraData.lName,
        uLName: extraData.lName,
        usertype: 10,
        uPhone: extraData.phone,
        school: extraData.school
      }



      let newData: ISchoolUserCross = {
        schoolKey: extraData.school,
        userKey: user.uid,
        canChaperone: false,
        canSub: false,
        isAdmin: false,
        canLead: false,
        hide: false

      }



      this.afs.collection('schoolUsers').ref.add(newData)
        .then((res) => {
          console.log('dataService: createOne success: res: ', res);
        }).catch(err => {
          console.error('dataService: createOne: error: ', err);
        });







    } else {

      data = {
        uid: user.uid,
        email: user.email,
        isVerified: user.emailVerified,

        // usertype: user.usertype ? user.usertype : 10,
        // uPhone: user.uPhone ? user.uPhone : 0
      }
    }
    if (data.isVerified && this.returnPage) {
      this.router.navigate([this.returnPage]);
    }
    return userRef.set(data, { merge: true })

  }


  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.isUser.next(false)

      this.router.navigate(['/tabs/login']);
    });
  }
}