import { MainService } from './../core/services/main.service';
import { Component, OnInit } from '@angular/core';
import { ISubJob } from '../core/interfaces';
import { NavParams, ModalController } from '@ionic/angular';
import { AngFireStoreService } from '../core/services/afs.service';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit {
  subJob: ISubJob;
  // canAccept: boolean = false;
  constructor(private navParams: NavParams, private afService: AngFireStoreService, private modalController: ModalController, private mainService: MainService) { }

  ngOnInit() {
    this.subJob = this.navParams.get('subJobData');
    // this.canAccept = this.navParams.get('canAccept');
    console.log(this.subJob);


  }


  isAcceptable() {
    if (this.subJob.subKey && this.subJob.subKey.length > 2) {
      return false
    }
    return true
  }


  acceptJob() {
    const data = { firstName: this.mainService.thisUser.uFName, lastName: this.mainService.thisUser.uLName, subKey: this.mainService.thisUser.key };
    this.afService.getAngDoc('subJobs', this.subJob.key).set(data, { merge: true }).then(() => {
      this.closeModal();
    })
  }
  genDate(): Date {
    if (this.subJob) {
      return new Date(this.subJob.year, this.subJob.month - 1, this.subJob.day);
    }
    return null
  }
  closeModal() {
    this.modalController.dismiss();
  }
}
