import { ProfileComponent } from './profile/profile.component';
import { MyJobsComponent } from './my-jobs/my-jobs.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPage } from './login/login.page';
import { AuthGuard } from './core/auth.guard';
import { TabsPage } from './tabs/tabs.page';
import { SubJobListComponent } from './sub-job-list/sub-job-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs/list',
    pathMatch: 'full'
  },



  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'myJobs'
      },
      {
        path: 'login', component: LoginPage
      },
      {
        path: 'profile', component: ProfileComponent
      },
      {
        path: 'list', component: SubJobListComponent
      },
      {
        path: 'myjobs', component: MyJobsComponent
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
