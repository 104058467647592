import { MainService } from './services/main.service';



import { Injectable } from '@angular/core';
import {
    Route,
    Router,
    CanLoad,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './services/auth.service';
import { take, map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AngFireStoreService } from './services/afs.service';

@Injectable()
export class AdminGuard implements CanActivate {

    currentState: boolean;
    stateSet: boolean = false;
    adminSubscription: Subscription;
    constructor(private router: Router, private auth: AuthService, private mainService: MainService) {
        console.log("AuthGuard constructor")
    }




    isAdmin: boolean = null;
    goToLogin = true

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let unVerified = false;
        if (!this.adminSubscription) {

            this.adminSubscription = this.mainService.getMeSubject().subscribe((value) => {
                if (value) {
                    this.isAdmin = value.isAdmin ? true : false;
                    this.goToLogin = false;
                } else {
                    this.isAdmin = false;
                    this.goToLogin = true;

                }

            })
        };

        let getLogin = new Promise<boolean>((resolve, reject) => {
            if (!this.isAdmin) {
                this.mainService.setGuardReason('Must be admin.');
            }
            resolve(this.isAdmin);
            if (this.goToLogin) {
                const retPage = next.url[0].path;
                this.router.navigate(["login", { page: retPage, unVerified: unVerified }]);

            }

        });

        return getLogin;

    }


}





