import { AngFireStoreService } from 'src/app/core/services/afs.service';
import { MainService } from './main.service';
import { Subscription } from 'rxjs';
import { FCM, NotificationData } from '@ionic-native/fcm/ngx';


import { Device } from '@ionic-native/device/ngx';
import { Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { bdIsApp } from '../functions';
import { PushEndPoint } from '../classes/PushEndPoint';
import { PUSH_ENDPOINT_TYPE } from '../interfaces/PUSH_ENDPOINT_TYPE';

/*** 
 *  The only reason this is a service currently is for the dependency injection for 
 * the services it depends on.
 */
@Injectable()
export class PushService implements OnDestroy {

  private m_isDestroyed: boolean = false;
  private m_tokenSubscription: Subscription | null = null;
  private m_notificationSub: Subscription | null = null;

  currentToken: PushEndPoint | null = null;
  someData: string = 'Start';

  constructor(private fcm: FCM, private platform: Platform, private device: Device, private mainService: MainService) {


    this.platform.ready().then(() => {
      if (!this.m_isDestroyed)
        this.pf_startPushSubscription();
    });
  }

  ngOnDestroy(): void {
    this.m_isDestroyed = true;

    if (this.m_tokenSubscription != null) {
      this.m_tokenSubscription.unsubscribe();
      this.m_tokenSubscription = null;
    }

    if (this.m_notificationSub != null) {
      this.m_notificationSub.unsubscribe();
      this.m_notificationSub = null;
    }
  }
  private pf_bdIsApp(): boolean {
    return (!document.URL.startsWith('http') || document.URL.startsWith('http://localhost:8080'))
  }
  private pf_startPushSubscription(): void {



    if (!this.pf_bdIsApp()) {
      console.log('Push notification endpoint standing down.  Invalid URL:', document.URL);
      return;
    }

    try {
      this.fcm.getToken().then((token: string) => {
        console.log('getToken provided token:', token);
        this.pf_handleToken(token);
      });

      this.m_tokenSubscription = this.fcm.onTokenRefresh().subscribe((token: string) => {
        console.log('observable provided token:', token);
        this.pf_handleToken(token);
      });

      this.m_notificationSub = this.fcm.onNotification().subscribe((data: NotificationData) => {

        console.log('notification received:', data);
        // this.fcmActivity.push('FCM Start');


        // if (data.wasTapped) {
        //   if (DiagMisc.logMiscSwitch.isEnabled())
        //     DiagMisc.logMiscSwitch.log("Push Received in background");            

        // } else {
        //   if (DiagMisc.logMiscSwitch.isEnabled())
        //     DiagMisc.logMiscSwitch.log("Push Received in foreground ");
        //   // this.fcmActivity.push('FCM FG: ' + JSON.stringify(data));
        // }
      });

    } catch (err) {
      console.error(`Failed to setup push endpoint handling:`, err);
    }
  }

  pf_handleToken(token: string): void {

    if (this.m_isDestroyed)
      return;

    // this.pf_runRequest_SecDevPushEndPointSetAsync(token);
    // const pushParms: IDevPushEndpointSet = { PushEndPointType: 'FB', PushEndPoint: token, DeviceDesc: DevDesc }; Dave with help from tom 8/28/2018

    if (token && typeof token == 'string' && token.length > 0) {

      const current: PushEndPoint | null = this.currentToken;

      let pushTypePlatform: PUSH_ENDPOINT_TYPE = this.pf_GetPushEndpointType();

      if (!current || current.pushEndPointToken !== token || current.pushEndPointType !== pushTypePlatform) {

        let devDesc: string = this.pf_GetDeviceDesc();
        const newEp = new PushEndPoint(pushTypePlatform, token, devDesc, this.device.uuid);
        console.log('New Push Endpoint:', newEp);

        this.currentToken = newEp;
        this.mainService.updatePushEndpoint(token, devDesc, pushTypePlatform, this.device.uuid);
      }
    }
  }


  private pf_GetPushEndpointType(): PUSH_ENDPOINT_TYPE {

    /**
     * THIS IS NEW PER tOM & dAVID Ko
     * To determine which target it is getting.
     */

    let pushTypePlatform: PUSH_ENDPOINT_TYPE = 'FI'; // FB = firebase android, and FI = firebase IOS

    if (this.device.platform.toUpperCase() == 'ANDROID') {
      pushTypePlatform = 'FB';
    }
    else if (this.device.platform.toUpperCase() == 'IOS' || this.device.platform.toUpperCase() == 'APPLE') {
      pushTypePlatform = 'FI';
    }
    else {

      console.error(`Our guess at using IOS to map to fi with a platform of '${this.device.platform}' was wrong.  Mapping to FI anyways!  Tom and Dave, get on this.`);
    }

    return pushTypePlatform;
  }

  private pf_GetDeviceDesc() {

    let devDesc: string = '';

    if (this.device) {

      if (this.device.manufacturer) {
        devDesc += this.device.manufacturer;
      }

      if (this.device.model) {
        devDesc += ': ' + this.device.model;
      }
      if (this.device.version) {
        devDesc += ': ' + this.device.version;
      }
    }
    else {
      devDesc = 'No Cordova, so no Description';
    }

    return devDesc;
  }

  // subscribeTo(topic: string): void {
  //   this.fcm.subscribeToTopic(topic);



  // }

  // unSubscribeFrom(topic: string): void {
  //   this.fcm.unsubscribeFromTopic(topic);

  // }

}
