import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { IRegister } from "../core/interfaces/IRegister";
import { MainService } from '../core/services/main.service';
import { ISchool, ISchoolUser } from '../core/interfaces';
import { Observable, Subscription } from 'rxjs';
import { AngFireStoreService } from '../core/services/afs.service';
import { map } from 'rxjs/operators';
import { faCoffee, faCircle, faCheckCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ISchoolUserCross } from '../core/interfaces/ISchoolUserCross';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {


  register: IRegister = { email: '', pw: '', pw2: '', fName: '', lName: '', phone: null, schoolArray: [] };
  private m_startRegister: IRegister = { email: '', pw: '', pw2: '', fName: '', lName: '', phone: null };
  loginDisabled: boolean = true;
  registerDisabled: boolean = true;
  loginError: number = 0;
  passwordStrength: number = 0;
  needVerification: boolean = false;
  returnPage: string = '';
  schools: Observable<ISchool[]>;
  schoolArray: ISchool[];
  schoolsArray2: ISchool[];
  schoolListLength = 0;
  stateArray: string[];
  cityArray: string[];
  citySchools: ISchool[];
  selectedState: string = null;
  selectedCity: string = null;
  selectedSchool: ISchool = null;
  closeResult: string;
  stateSelectValue: string = null;
  citySelectValue: string = null;
  schoolSelectValue: string = null;
  needEmail: boolean = false;
  myDataSub: Subscription;
  startEmail: string = null;
  pw: string = null;
  myData: ISchoolUser = null;
  selectedStates: string[] = [];
  selectedCities: string[] = [];
  selectedSchools: string[] = [];
  updateIcon = faEdit;
  private m_mySchools: string[];
  showStateSelect: boolean = false;
  showCitySelect = false;
  showSchoolSelect = false;
  selectedStateTemp: string[] = [];
  selectedCityTemp: string[] = [];
  selectedSchoolTemp: string[] = [];
  mySchoolUserCrossArray: ISchoolUserCross[];
  showLoader = true;
  constructor(public auth: AuthService, private mainService: MainService, private afs: AngFireStoreService) { }
  ngOnDestroy() {
    if (this.myDataSub)
      this.myDataSub.unsubscribe();

    if (this.mySchoolsSub)
      this.mySchoolsSub.unsubscribe();

    if (this.mySchoolsCollectionSub)
      this.mySchoolsCollectionSub.unsubscribe();
  }
  mySchoolsCollectionSub: Subscription;
  mySchoolsSub: Subscription;
  selectedSchoolsStart: string[] = [];
  gettingData = true;
  ngOnInit() {
    this.mySchoolsSub = this.afs.getCollection('schools', ref => ref.orderBy('State').orderBy('city').orderBy('name')).snapshotChanges().pipe(map(
      changes => {
        return changes.map(
          a => {
            const data = a.payload.doc.data() as ISchool;
            data.key = a.payload.doc.id;
            return data;
          });

      })).subscribe((data) => {
        this.schoolArray = data;
        this.citySchools = data;
        this.schoolListLength = data.length;
        this.setStateArray();
      })

    this.myDataSub = this.mainService.getMeSubject().subscribe((meData) => {
      if (meData) {

        this.mySchoolsCollectionSub = this.afs.getCollection('schoolUsers', ref => ref.where('userKey', '==', meData.uid)).snapshotChanges().pipe(map(
          changes => {
            return changes.map(
              a => {
                const data = a.payload.doc.data() as ISchoolUserCross;
                data.key = a.payload.doc.id;
                return data;
              });

          })).subscribe((data) => {
            this.mySchoolUserCrossArray = data;
            let startArray = [];
            this.mySchoolUserCrossArray.forEach((cross) => {
              startArray.push(cross.schoolKey);
            });
            this.selectedSchools = startArray.slice();
            this.selectedSchoolsStart = startArray.slice();
          })

        setTimeout(() => {
          this.gettingData = false;
          this.showLoader = false;
        }, 2000);

        if (meData.schoolArray) {
          this.register.schoolArray = meData.schoolArray;
          this.m_mySchools = meData.schoolArray;
          if (meData.schoolArray.indexOf(meData.school) < 0) {
            this.register.schoolArray.push(meData.school);
          }
        } else {
          this.register.schoolArray = [meData.school];
        }

        this.register.email = meData.email;
        this.m_startRegister.email = meData.email;
        this.register.fName = meData.uFName;
        this.m_startRegister.fName = meData.uFName;
        this.register.lName = meData.uLName;
        this.m_startRegister.lName = meData.uLName;
        this.register.phone = meData.uPhone;
        this.m_startRegister.phone = meData.uPhone;
        this.register.school = meData.school;
        this.register.gender = meData.gender;
        this.m_startRegister.gender = meData.gender;
        this.myData = meData;
        this.startEmail = meData.email;
      }
    })


  }
  nowThis(val) {
    console.log(val);
  }




  updateGender(gender: number) {
    this.register.gender = gender;
  }



  getSelectedStateIcon(val) {
    const stateIdx = this.selectedStateTemp.indexOf(val);

    return stateIdx >= 0 ? faCheckCircle : faCircle;
  }

  getSelectedCityIcon(val) {
    const cityIdx = this.selectedCityTemp.indexOf(val);
    const theBoolean = cityIdx >= 0;

    return cityIdx >= 0 ? faCheckCircle : faCircle;
  }

  getSelectedSchoolIcon(val) {
    const Idx = this.selectedSchoolTemp.indexOf(val);


    return Idx >= 0 ? faCheckCircle : faCircle;
  }
  toggleState(state: string) {
    const stateIndex = this.selectedStateTemp.indexOf(state);
    if (stateIndex >= 0) {
      this.selectedStateTemp.splice(stateIndex, 1);
    } else {
      this.selectedStateTemp.push(state);
    }

  }

  toggleCity(city) {
    const cityIndex = this.selectedCityTemp.indexOf(city);
    if (cityIndex >= 0) {
      this.selectedCityTemp.splice(cityIndex, 1);
    } else {
      this.selectedCityTemp.push(city);
    }

  }

  toggleSchool(schoolKey: string) {
    const schoolIndex = this.selectedSchoolTemp.indexOf(schoolKey);
    if (schoolIndex >= 0) {
      this.selectedSchoolTemp.splice(schoolIndex, 1);
    } else {
      this.selectedSchoolTemp.push(schoolKey);
    }

  }
  updateState(why) {
    console.log(this.selectedStates);
    if (why = 1) {
      this.selectedStates = this.selectedStateTemp.slice();
      this.setCityArray()
    }

    this.showStateSelect = false;

  }
  updateCity(why) {
    console.log(this.selectedCities);
    if (why = 1) {
      this.selectedCities = this.selectedCityTemp.slice();
      this.setSchoolArray()
    }

    this.showCitySelect = false;

  }
  updateSchool(why) {

    if (why = 1) {
      this.selectedSchools = this.selectedSchoolTemp.slice();

      this.schoolsChanged()
    }

    this.showSchoolSelect = false;



  }
  showStateDiv() {
    console.log('showState');
    console.log('showState', this.selectedStates.slice());

    this.selectedStateTemp = this.selectedStates.slice();
    this.showStateSelect = true;

  }
  showCityDiv() {
    this.selectedCityTemp = this.selectedCities.slice();
    this.showCitySelect = true;

  }

  showSchoolDiv() {

    this.showSchoolSelect = true;

  }
  getUserType(usertype) {
    let type: string = 'Not Set Yet';
    switch (usertype) {
      case 1:
        type = 'School Administrator'
        break;
      case 2:
        type = 'Trip Leader'
        break;
      case 3:
        type = 'Chaperone'
        break;
      case 4:
        type = 'Parent'
        break;
      case 5:
        type = 'Adult'
        break;

    }
    return type;
  }

  getError() {
    return this.errorText;
  }
  errorText: string = null;
  getSelectedSchoolsCount(): string {
    return this.selectedSchools.length + ' Selected';
  }

  sendRegister() {

    if (this.register.email != this.m_startRegister.email ||
      this.register.fName != this.m_startRegister.fName ||
      this.register.lName != this.m_startRegister.lName ||
      this.register.phone != this.m_startRegister.phone ||
      this.register.gender != this.m_startRegister.gender ||
      (this.m_addSchoolArray && this.m_addSchoolArray.length > 0) ||
      (this.m_deleteSchoolArray && this.m_deleteSchoolArray.length > 0)) {

      this.gettingData = true;

      this.errorText = null;
      // todo - check for email already..

      if (!this.startEmail || !this.register.email || this.register.email.length == 0 || !(this.register.email.length > 5 && this.register.email.split('@').length > 1 && this.register.email.split('.').length > 1)) {
        // email addresses not set - abort
        this.errorText = "Please enter a valid EMail Address";
        return;
      }


      let verified: boolean = this.startEmail.toUpperCase() == this.register.email.toUpperCase() ? this.myData.isVerified : false
      const user = this.auth.getCurrentUser();



      const data = {
        email: this.register.email,
        isVerified: verified,
        uFName: this.register.fName,
        displayName: this.register.fName + ' ' + this.register.lName,
        uLName: this.register.lName,
        uPhone: this.register.phone,
        schoolArray: this.selectedSchools,
        gender: this.register.gender
      }

      if (this.startEmail.toUpperCase() !== this.register.email.toUpperCase()) {
        this.auth.emailAlready(this.register.email).then((theReturn) => {


          if (theReturn && theReturn.length > 0) {
            this.errorText = "The Email you entered is already in use.";

          } else {


            this.auth.signInOnly(this.startEmail, this.pw).then((credential) => {
              credential.user.updateEmail(this.register.email).then(() => {
                // now update the user!!
                this.afs.getAngDoc<ISchoolUser>('users', user.uid).set(data, { merge: true });
                this.updateSchoolTables();
                credential.user.sendEmailVerification();

              });
            })
          }
        });

      } else {
        this.afs.getAngDoc<ISchoolUser>('users', user.uid).set(data, { merge: true });
        this.updateSchoolTables();
        if (!this.myData.isVerified) {
          user.sendEmailVerification();
        };
      }

    }


  }


  private m_addSchoolArray: string[] = [];
  private m_deleteSchoolArray: string[] = [];
  schoolsChanged() {
    let addArray = [];
    let deleteArray = this.selectedSchoolsStart.slice();

    this.selectedSchools.forEach((schoolKey) => {
      const delIdx = deleteArray.indexOf(schoolKey);
      deleteArray.splice(delIdx, 1);
      if (this.selectedSchoolsStart.indexOf(schoolKey) < 0) {
        addArray.push(schoolKey);
      }
    });

    this.m_addSchoolArray = addArray;
    this.m_deleteSchoolArray = deleteArray;


  }
  logOut() {
    this.auth.signOut();
  }
  updateSchoolTables() {


    let convertToKeys = []
    this.mySchoolUserCrossArray.forEach((cross) => {
      if (this.m_deleteSchoolArray.indexOf(cross.schoolKey) >= 0) {
        convertToKeys.push(cross.key);
      }
    })


    convertToKeys.forEach((crossKey) => {


      this.afs.getAngDoc('schoolUsers', crossKey).delete().then(function () {
        console.log("Document successfully deleted!", crossKey);
      }).catch(function (error) {
        console.error("Error removing document: ", error);
      });

    })

    this.m_addSchoolArray.forEach((newSchool) => {
      let newData: ISchoolUserCross = {
        schoolKey: newSchool,
        userKey: this.myData.uid,
        canChaperone: false,
        canSub: false,
        isAdmin: false,
        canLead: false,
        hide: false
      }

      this.afs.getCollection('schoolUsers').ref.add(newData)
        .then((res) => {
          console.log('dataService: createOne success: res: ', res);
        }).catch(err => {
          console.error('dataService: createOne: error: ', err);
        });



    })


  }







  checkRegisterStatus() {
    if (this.register.email && this.register.email.split('@').length == 2 && this.register.email.split('.').length >= 2 && this.register.fName.length > 0 && this.register.lName.length > 0 && this.selectedSchools.length > 0) {
      return false
    }
    return true;
  }


  resetPW() {



    if (this.register.email.length > 5 && this.register.email.split('@').length > 1 && this.register.email.split('.').length > 1) {
      this.auth.resetPW(this.register.email)
    } else {
      this.needEmail = true;
      setTimeout(() => {
        this.needEmail = false;
      }, 2000)
    }

  }

  resendVerification() {
    console.log(`saending...`);
    this.auth.reSendVerification();
  }



  setStateArray() {
    if (this.schoolArray) {
      let newArray = [];
      this.schoolArray.forEach((school) => {
        if (newArray.indexOf(school.State) < 0) {
          newArray.push(school.State)
        }
      });
      this.stateArray = newArray.sort();
      // this.selectedStates = newArray.slice();

      this.setCityArray();
    }

  }

  limitSchoolsToStates() {
    let newArray: ISchool[] = [];
    let alreadySelected: ISchool[] = [];
    this.schoolArray.forEach((school) => {
      if (this.selectedSchools.indexOf(school.key) >= 0) {
        alreadySelected.push(school);
      };
      if (this.selectedStates.indexOf(school.State) >= 0 && this.selectedSchools.indexOf(school.key) < 0) {
        newArray.push(school);
      }
    });


    alreadySelected.push(...newArray.sort(this.compare))
    this.citySchools = alreadySelected;

  }

  citySelected(val) {
    const cityIdx = this.selectedCityTemp.indexOf(val);
    const theBoolean = cityIdx >= 0;

    return theBoolean;
  }

  setCityArray() {
    let newCityArray = this.selectedCities.slice();
    let addThisArray = [];
    this.schoolArray.forEach((school) => {
      if (addThisArray.indexOf(school.city + ', ' + school.State) < 0 && this.selectedStates.indexOf(school.State) >= 0 && newCityArray.indexOf(school.city + ', ' + school.State) < 0) {
        addThisArray.push(school.city + ', ' + school.State);
      }
    });
    newCityArray.push(...addThisArray.sort())
    this.cityArray = newCityArray;

    this.limitSchoolsToStates();
  }


  setSchoolArray() {

    // selected Cities

    let alreadySelected: ISchool[] = [];

    let newArray: ISchool[] = [];
    this.schoolArray.forEach((school) => {
      if (this.selectedSchools.indexOf(school.key) >= 0) {
        alreadySelected.push(school);
      }
      this.selectedCities.forEach((cityState) => {
        const city = cityState.split(',')[0].trim();
        const state = cityState.split(',')[1].trim();
        if (school.city == city && school.State == state && this.selectedSchools.indexOf(school.key) < 0) {
          newArray.push(school)
        }
      });
    })


    alreadySelected.push(...newArray.sort(this.compare))
    this.citySchools = alreadySelected;

  }


  isSelected(value, selectField) {
    let retVal: boolean;
    if (selectField == 'state') {
      retVal = this.selectedStates.indexOf(value) >= 0;
    }
    return retVal;
  }

  getStateSelectText() {

    return this.selectedStates.indexOf('*') >= 0 ? 'All States' : this.selectedStates.toString().replace(",", ", ");
  }


  getCitySelectText() {
    return this.selectedCities.indexOf('*') >= 0 ? 'All Cities' : this.selectedCities.length + ' Selected Cities';
  }


  selectSchool(schoolArray: string[]) {
    this.register.schoolArray = schoolArray;
  }
  private compare(a: ISchool, b: ISchool) {
    if (a.name < b.name)
      return -1;
    if (a.name > b.name)
      return 1;
    return 0;
  }

}
