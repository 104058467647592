import { JobDetailComponent } from './../job-detail/job-detail.component';
import { MainService } from './../core/services/main.service';
import { map } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { AngFireStoreService } from './../core/services/afs.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ISubJob, ISubJobObject } from 'src/app/core/interfaces';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SubService } from '../core/services/sub.service';

@Component({
  selector: 'app-my-jobs',
  templateUrl: './my-jobs.component.html',
  styleUrls: ['./my-jobs.component.scss']
})
export class MyJobsComponent implements OnInit {
  subJobArray: { schoolName: string, jobs: ISubJob[] }[] = [];
  delJob: string;
  subJobObserver: Observable<ISubJob[]>

  private m_jobs: ISubJobObject = null;

  showDates: number = 1;

  private subJobSubscription: Subscription;
  jobKeys: string[] = [];
  constructor(private subService: SubService, private router: Router, private afService: AngFireStoreService, private modalController: ModalController, private mainService: MainService) { }
  genDate(day: number, month: number, year: number): Date {
    return new Date(year, month - 1, day);
  }
  setShow(val: number) {
    this.showDates = val;
    this.listMyJobs();
  }
  ngOnInit() {
    this.subJobSubscription = this.subService.getSubJobArrayBehSubj().subscribe((subJobObject) => {

      this.m_jobs = subJobObject;
      this.listMyJobs();


    });

  }
  listMyJobs() {
    if (this.m_jobs) {
      const subJobObject = this.m_jobs;


      let SchoolArray = []


      for (const key in subJobObject) {
        console.log(subJobObject[key]);

        let mySubArray: ISubJob[] = [];
        const theJobs = subJobObject[key].jobs;

        theJobs.forEach((job) => {
          const jobDate = new Date(job.year, job.month-1, job.day);


          const today = new Date();


          const ago = new Date(today.getTime() - 2592000000); 
          
          if (this.showDates == 1) {
            if (job.subKey && job.subKey == this.mainService.thisUser.key && jobDate >= today) {
              mySubArray.push(job);

            }
          } else if (this.showDates == 2) {
            if (job.subKey && job.subKey == this.mainService.thisUser.key && jobDate <= today && jobDate >= ago) {
              mySubArray.push(job);

            }
          } else {
                if (job.subKey && job.subKey == this.mainService.thisUser.key) {
            mySubArray.push(job);

          }
          }




      






        });
        if (mySubArray && mySubArray.length > 0) {
          SchoolArray.push({ schoolName: subJobObject[key].schoolName, jobs: mySubArray })
        }

      }

      this.subJobArray = SchoolArray.sort(this.compare);

      // let myJobArray: ISubJob[] = [];


      // subJobArr.forEach((job) => {
      //   if (job.subKey && job.subKey == this.mainService.thisUser.key) {
      //     myJobArray.push(job);


      //   }
      // })
      // this.subJobArray = myJobArray;


    }
  }


  compare(a: { schoolName: string, jobs: ISubJob[] }, b: { schoolName: string, jobs: ISubJob[] }) {
    if (a.schoolName < b.schoolName)
      return -1;
    if (a.schoolName > b.schoolName)
      return 1;
    return 0;
  }



  detailModel: HTMLIonModalElement;

  async showDetail(jobData: ISubJob) {
    this.detailModel = await this.modalController.create({
      component: JobDetailComponent,
      componentProps: { subJobData: jobData, canAccept: false }
    });
    return await this.detailModel.present();
  }
}
