import { PUSH_ENDPOINT_TYPE } from "../interfaces/PUSH_ENDPOINT_TYPE";
import { PushEndPoint } from "../classes/PushEndPoint";
import { Platform } from '@ionic/angular';
import { SchoolUser } from './../schoolUser';

import { AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngFireStoreService } from './afs.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { ISchool, ISchoolUser, IDevices } from '../interfaces';
import { AuthService } from './auth.service';
import { firestore } from 'firebase';
import { eVersionStatus } from "../interfaces/eVersionStatus";
import { IVersion } from "../interfaces/IVersion";

export enum eNotificationType {
  newJob,
  default
}


@Injectable()
export class MainService {
  showGradeArray: string[] = [];
  gradesSet: boolean = false;
  displayName: string | null = null;
  guardReason: string | null = null;
  private m_sounding: boolean = false;
  private m_audio: any;
  private m_newJobSound = new Audio('assets/audio/alarm1.mp3');
  private m_defaultSound = new Audio('assets/audio/alarm2.mp3');
  private meSubject = new BehaviorSubject<ISchoolUser>(null);
  schoolArray: ISchool[];
  thisUser: ISchoolUser | null;
  private m_appInForeground: boolean = true;
  private m_pauseSub: Subscription | null = null;
  private m_resumeSub: Subscription | null = null;
  private m_behSubjToDispose: BehaviorSubject<any>[] = [];;
  private m_stringsToDispose: string[] = [];
  muteSound: boolean = true;

  usersCollection: AngularFirestoreCollection<ISchoolUser>;

  constructor(private fsService: AngFireStoreService, private auth: AuthService, private platform: Platform) {
    this.m_newJobSound.preload = 'auto';
    this.auth.user.subscribe(userData => {
      console.log('user Data ', userData);
      if (userData && userData) {
        this.displayName = userData.displayName;
        this.m_stringsToDispose.push(this.displayName);
      } else {
        this.disposeAll()
      }
      if (userData && !userData.key && userData.uid) {
        userData.key = userData.uid;
      }
      this.thisUser = userData;
      if (this.updatePushOnThisUser) {
        this.pf_updatePush();
      }





      this.meSubject.next(userData);
      if (userData && userData.school) {


      }

    })

    platform.ready().then(() => {
      // this.statusBar.show();
      // this.statusBar.styleLightContent();
      this.m_pauseSub = this.platform.pause.subscribe(() => {
        //Hello pause
        console.log(`paused....`);
        this.m_appInForeground = false;
      });

      //Subscribe on resume
      this.m_resumeSub = this.platform.resume.subscribe(() => {
        (window as any)['paused'] = 0;
        console.log(`resumed....` + this.m_resumeSub);
        this.m_appInForeground = true;
      });




    });

  }
  private m_mypushEP: PushEndPoint | null = null
  private inUpdatePush: boolean = false;
  private updatePushOnThisUser = false;

  /**
   * 
   * @param token the FCM Token
   * @param desc Device description
   * @param devType FB - Android or FI iOS
   * @param uid device uuid
   */
  updatePushEndpoint(token: string, desc: string, devType: PUSH_ENDPOINT_TYPE, uid: string) {
    if (!this.m_mypushEP || this.m_mypushEP.pushEndPointToken != token) {
      this.inUpdatePush = true;
      this.m_mypushEP = new PushEndPoint(devType, token, desc, uid);

      if (this.thisUser) {


        this.pf_updatePush()


      } else {
        this.updatePushOnThisUser = true;

      } // end of else of if thisUser
    }

  }

  versionBehSubj = new BehaviorSubject<{ must: eVersionStatus, should: eVersionStatus, newer: eVersionStatus }>({ must: eVersionStatus.checking, should: eVersionStatus.checking, newer: eVersionStatus.checking });
  checkVersion(appName: string, thisVersion: number): void {
    let verCheck: { must: eVersionStatus, should: eVersionStatus, newer: eVersionStatus } = { must: eVersionStatus.checking, should: eVersionStatus.checking, newer: eVersionStatus.checking };
    this.fsService.getCollection<IVersion>('appversions', ref => ref.where('appName', '==', appName)).valueChanges().subscribe((appData) => {
      const appDataOne: IVersion = appData[0];
      if (appDataOne) {
        if (appDataOne.shouldUpdate >= thisVersion) {
          verCheck.should = eVersionStatus.true;
        } else if (appDataOne.shouldUpdate < thisVersion) {
          verCheck.should = eVersionStatus.false;
        }
        if (appDataOne.mustUpdate >= thisVersion) {
          verCheck.must = eVersionStatus.true;
        } else if (appDataOne.mustUpdate < thisVersion) {
          verCheck.must = eVersionStatus.false;
        }
        if (appDataOne.currentVersion > thisVersion) {
          verCheck.newer = eVersionStatus.true;
        } else if (appDataOne.currentVersion <= thisVersion) {
          verCheck.newer = eVersionStatus.false;
        }
      }
      this.versionBehSubj.next(verCheck);
    })
  }
  private pf_updatePush() {


    let userDeviceUIDArray: string[] = [];

    if (!this.thisUser.deviceKeys || !Array.isArray(this.thisUser.deviceKeys) || (Array.isArray(this.thisUser.deviceKeys) && this.thisUser.deviceKeys.indexOf(this.m_mypushEP.uuid) < 0)) {
      if (!this.thisUser.deviceKeys || !Array.isArray(this.thisUser.deviceKeys)) {
        userDeviceUIDArray = [this.m_mypushEP.uuid];
      } else {
        userDeviceUIDArray = this.thisUser.deviceKeys.slice();
        userDeviceUIDArray.push(this.m_mypushEP.uuid);

      }



      this.fsService.getAngDoc('users', this.thisUser.key).set({ 'deviceKeys': userDeviceUIDArray }, { merge: true }).then(() => {
        console.log(`Added device keys: `, userDeviceUIDArray)
      })




    }



    const nowTS: firestore.Timestamp = firestore.Timestamp.fromDate(new Date());
    console.log(`Firestore Timestamp: `, nowTS)
    const data: IDevices = { userKey: this.thisUser.key, token: this.m_mypushEP.pushEndPointToken, uuid: this.m_mypushEP.uuid, description: this.m_mypushEP.devDesc, lastUse: nowTS }
    this.fsService.getAngDoc('devices', this.m_mypushEP.uuid).set(data, { merge: true }).then(() => {
      console.log(`Device Collection Updated: `, nowTS);
      this.updatePushOnThisUser = false;
    })
  }



  ngOnDestroy(): void {
    if (this.m_pauseSub)
      this.m_pauseSub.unsubscribe();
    if (this.m_resumeSub)
      this.m_resumeSub.unsubscribe();

  }
  getMeSubject() {
    return this.meSubject;
  }



  /**
   * 
   * @param alertType NewJob=1 - Default = 2
   */
  sendAlert(alertType: number = 0): void {
    let sound: HTMLAudioElement = this.m_newJobSound;
    let vibrationPattern: number[] = [100, 100, 100];
    let notifType: eNotificationType;
    switch (alertType) {
      case 1:   // new Job
        if (this.m_newJobSound && this.m_newJobSound.networkState == 1) {
          sound = this.m_newJobSound;
        } else {
          sound = new Audio('assets/audio/alarm6.mp3');
        }
        vibrationPattern = [100, 100, 100, 200, 500];
        notifType = eNotificationType.newJob;
        break;
      case 2:  // default
        if (this.m_defaultSound && this.m_defaultSound.networkState == 1) {
          sound = this.m_defaultSound;
        } else {
          sound = new Audio('assets/audio/alarm6.mp3');
        }
        vibrationPattern = [100, 100, 100, 100, 100, 500, 100, 100, 100, 100, 100];
        notifType = eNotificationType.default;
        break;

      default:
        if (this.m_defaultSound && this.m_defaultSound.networkState == 1) {
          sound = this.m_defaultSound;
        } else {
          sound = new Audio('assets/audio/alarm6.mp3');
        }
        vibrationPattern = [100, 100, 100];
        notifType = eNotificationType.default;
        break;
    }



    this.pf_localNotification(notifType);

    this.pf_alertUser(sound, vibrationPattern);


  }


  private pf_localNotification(type: eNotificationType): void {
    let notifText: string | undefined;
    switch (type) {
      case eNotificationType.newJob:
        notifText = 'A New Job Is Available.';
        break;

      default:
        notifText = undefined;
        break;
    }

    // this.localNotification.schedule({
    //   id: 1,
    //   text: notifText,
    //   silent: true
    // });
  }



  // getIncidentFromAdashiServer(incidentId: string): Promise<CadIncidentDLS> {
  //   return this.serverData.getCadIncident(incidentId);
  // }

  stopAudio(): void {
    if (this.m_sounding) {
      this.m_audio.pause();
      console.log(`ALT - Audio Paused`);
      this.m_sounding = false;
    }

  }

  private pf_alertUser(sound: any, vibratePattern: number[]): void {
    console.log(`ALT - START`);

    if (!this.m_sounding && this.m_appInForeground) {
      console.log(`ALT - Audio started`);
      let vib: boolean = true;
      let aud: boolean = true;
      const sum: number = vibratePattern.reduce((a, b) => a + b, 0);
      console.log(sum); // 6
      setTimeout(() => {
        console.log(`ALT - vib ended`);
        vib = false;
        if (!aud) {
          console.log(`ALT - alert ended`);
          this.m_sounding = false;
        }
      }, sum);


      if (!this.muteSound) {
        this.m_sounding = true;
        this.m_audio = sound;
        this.m_audio.play();
        this.m_audio.onended = () => {
          console.log(`ALT - Audio Done`);
          aud = false;
          if (!vib) {
            console.log(`ALT - alert ended`);
            this.m_sounding = false;
          }
        };



      }
      if (vibratePattern.length > 0) {
        // this.vibration.vibrate(vibratePattern);
      }


    }
  }






  setDisplayName(val: string | null) {
    this.displayName = val;
  }




  private m_collectionsToDispose: AngularFirestoreCollection[] = [];
  private m_documentsToDispose: AngularFirestoreDocument[] = [];
  disposeAll() {
    this.m_collectionsToDispose.forEach((collection) => {
      collection = null;
    });
    this.m_documentsToDispose.forEach((document) => {
      document = null;
    });

    this.m_behSubjToDispose.forEach((behaviorSubject) => {
      behaviorSubject.next(null);
    });

    this.m_stringsToDispose.forEach((string) => {
      string = null;
    });
  }

  setGuardReason(val: string) {
    this.guardReason = val;
    setTimeout(() => {
      this.guardReason = null;
    }, 2000);
  }
  toggleGrade(grade: string) {
    const gradeIndex = this.showGradeArray.indexOf(grade);
    if (gradeIndex >= 0) {
      this.showGradeArray.splice(gradeIndex, 1);
    } else {
      this.showGradeArray.push(grade);
    }
  }

  addInitialGrades(gradeArray: string[]) {
    this.showGradeArray = gradeArray;
  }





  updateSchoolUser(schoolUserData: SchoolUser) {
    const promise = new Promise((resolve, reject) => {
      if (schoolUserData.key) {
        const userRef: AngularFirestoreDocument<any> = this.fsService.getAngDoc('users', schoolUserData.key);


        userRef.set(schoolUserData.getDataAsISchoolUser(), { merge: true }).then((something) => {
          console.log('updated data: ', something)
          resolve(null);
        })

      } else {




        this.usersCollection.ref.add(schoolUserData.getDataAsISchoolUser())
          .then((res) => {
            console.log('dataService: createOne success: res: ', res);
            resolve(res);
          }).catch(err => {
            console.error('dataService: createOne: error: ', err);
            reject(err);
          });
      }
    })

    return promise;
  }




}
