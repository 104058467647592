import { MainService } from './../core/services/main.service';
import { JobDetailComponent } from './../job-detail/job-detail.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ISubJob, ISubJobObject } from 'src/app/core/interfaces';
import { Router } from '@angular/router';
import { AngFireStoreService } from 'src/app/core/services/afs.service';
import { SubService } from 'src/app/core/services/sub.service';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { faVolumeMute, faCircle } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-sub-job-list',
  templateUrl: './sub-job-list.component.html',
  styleUrls: ['./sub-job-list.component.scss']
})
export class SubJobListComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.subJobSubscription.unsubscribe();
  }
  subJobArray: { schoolName: string, jobs: ISubJob[] }[] = [];

  faVolumeMute = faVolumeMute;


  delJob: string;
  soundMuted: boolean = true;
  subJobObserver: Observable<ISubJob[]>
  noJobs: boolean = true;
  private subJobSubscription: Subscription;
  constructor(private subService: SubService, private router: Router, private afService: AngFireStoreService, private modalController: ModalController, private mainService: MainService) { }
  genDate(day: number, month: number, year: number): Date {
    return new Date(year, month - 1, day);
  }
  updateMute() {
    this.mainService.muteSound = !this.mainService.muteSound
  }
  getMuteSound() {
    return this.mainService.muteSound;
  }


  getMute() {
   return faVolumeMute
  }



  ngOnInit() {
    if (!this.subJobSubscription) {
      
      
      this.subJobSubscription = this.subService.getSubJobArrayBehSubj().subscribe((subJobObject: ISubJobObject) => {
        let noJobs = true;
        if (subJobObject) {



          let SchoolArray = []


          for (const key in subJobObject) {
            console.log(subJobObject[key]);

            let noSubArray: ISubJob[] = [];
            const theJobs = subJobObject[key].jobs;

            theJobs.forEach((job) => {



              const jobDate = new Date(job.year, job.month - 1, job.day);


              const today = new Date();






              if ((!job.subKey || job.subKey.length == 0) && jobDate >= today) {
                noSubArray.push(job);

              }






            });
            if (noSubArray && noSubArray.length > 0) {
              SchoolArray.push({ schoolName: subJobObject[key].schoolName, jobs: noSubArray });
              noJobs = false;
            }

          }

          this.subJobArray = SchoolArray.sort(this.compare);
          
        }
        this.noJobs = noJobs;
      });

    }

  }

  compare(a: { schoolName: string, jobs: ISubJob[] }, b: { schoolName: string, jobs: ISubJob[] }) {
    if (a.schoolName < b.schoolName)
      return -1;
    if (a.schoolName > b.schoolName)
      return 1;
    return 0;
  }

  detailModel: HTMLIonModalElement;

  async showDetail(jobData: ISubJob) {
    this.detailModel = await this.modalController.create({
      component: JobDetailComponent,
      componentProps: { subJobData: jobData, canAccept: true }
    });
    return await this.detailModel.present();
  }







}
