import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { AngFireStoreService } from "./afs.service";
import { MainService } from "./main.service";
import { ISubJob, ISchoolUserCross, ISchool, ISubJobObject } from "../interfaces";
import { AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Subscription, BehaviorSubject, Observable } from "rxjs";
import { SubJob } from "../SubJob";


@Injectable()
export class SubService {
    private m_meSubjectSubscription: Subscription;
    subJobCollection: AngularFirestoreCollection<ISubJob>;
    subJobCollectionBehSubj = new BehaviorSubject<AngularFirestoreCollection<ISubJob>>(null);


    subJobArrayBehSubj = new BehaviorSubject<ISubJobObject>(null);

    subJobObserver: Observable<ISubJob[]>


    private m_subscriptionsToDispose: Subscription[] = [];
    currentJobKeys: string[] = [];
    schoolCrossSub: Subscription;
    mySubSchools: ISchoolUserCross[];
    subCOuntOne = 0;

    constructor(private fsService: AngFireStoreService, private mainService: MainService) {

        // this.m_meSubjectSubscription = this.mainService.getMeSubject().subscribe((myData) => {
        //     if (myData) {
        //         if (myData.school) {
        //             this.subJobCollection = this.fsService.getCollection('subJobs', ref => ref.where('schoolKey', '==', myData.school).orderBy('year', "desc").orderBy('month', "desc").orderBy('day', "desc"));
        //             this.pf_subJobSubscribe()
        //             this.subJobCollectionBehSubj.next(this.subJobCollection);
        //         }
        //     }
        // })
        // this.m_subscriptionsToDispose.push(this.m_meSubjectSubscription)


        this.m_meSubjectSubscription = this.mainService.getMeSubject().subscribe((myData) => {
            this.subCOuntOne++;
            console.log('SubScription Count in SubService...', this.subCOuntOne);
            if (myData) {

                this.fsService.getCollection<ISchoolUserCross>('schoolUsers', ref => ref.where('userKey', '==', myData.uid).where('canSub', '==', true)).valueChanges().subscribe((newSubSchools) => {
                    this.subCOuntOne++;

                    console.log('SubScription Count in SubService...(Should be 2 here) ', this.subCOuntOne);

                    this.mySubSchools = newSubSchools;
                    this.pf_updateSubSchools();

                })


            }


        });


    }


    subJobObject: ISubJobObject = {};

    private pf_updateSubSchools() {

        this.mySubSchools.forEach((subSchool) => {
            this.fsService.getAngDoc<ISchool>('schools', subSchool.schoolKey).valueChanges().subscribe((schoolData) => {
                if (!this.subJobObject[subSchool.schoolKey]) {
                    this.subJobObject[subSchool.schoolKey] = { schoolName: '', jobs: [], currentJobKeys: [] };
                }
                this.subJobObject[subSchool.schoolKey].schoolName = schoolData.name;
            });

            this.fsService.getCollection<ISubJob>('subJobs', ref => ref.where('schoolKey', '==', subSchool.schoolKey).orderBy('year', "desc").orderBy('month', "desc").orderBy('day', "desc")).snapshotChanges().pipe(map(
                changes => {
                    return changes.map(
                        a => {
                            const data = a.payload.doc.data() as ISubJob;
                            data.key = a.payload.doc.id;
                            return data;
                        });

                })).subscribe((subJobArr) => {
                    if (!this.subJobObject[subSchool.schoolKey]) {
                        this.subJobObject[subSchool.schoolKey] = { schoolName: '', jobs: [], currentJobKeys: [] };
                    }
                    this.subJobObject[subSchool.schoolKey].jobs = subJobArr;
                    if (!this.subJobObject[subSchool.schoolKey].currentJobKeys) {
                        this.subJobObject[subSchool.schoolKey].currentJobKeys = [];
                    }


                    // this.userArray = userArray;
                    let jobKeys: string[] = [];
                    let newJob: boolean = false;
                    subJobArr.forEach((job) => {
                        if (!job.subKey || job.subKey.length == 0) {

                            jobKeys.push(job.key);
                            if (this.subJobObject[subSchool.schoolKey].currentJobKeys.indexOf(job.key) == -1) {
                                newJob = true;
                            }
                        }
                    })
                    this.subJobArrayBehSubj.next(this.subJobObject);
                    this.subJobObject[subSchool.schoolKey].currentJobKeys = jobKeys;
                    if (newJob) {
                        this.mainService.sendAlert(1);
                    }
















                })


        })


        // now sort schools by name



    }

    /*


    private pf_subJobSubscribe() {
        
        this.subJobObserver = this.subJobCollection.snapshotChanges().pipe(map(
            changes => {
                return changes.map(
                    a => {
                        const data = a.payload.doc.data() as ISubJob;
                        data.key = a.payload.doc.id;
                        return data;
                    });
                    
                }));
                this.subJobObserver.subscribe(
                    (subJobArr: ISubJob[]) => {
                        // this.userArray = userArray;
                        let jobKeys: string[] = [];
                        let newJob: boolean = false;
                        subJobArr.forEach((job) => {
                            if (!job.subKey || job.subKey.length == 0) {
                                
                                jobKeys.push(job.key);
                                if (this.currentJobKeys.indexOf(job.key) == -1) {
                                    newJob = true;
                                }
                            }
                        })
                        this.subJobArrayBehSubj.next(subJobArr);
                        this.currentJobKeys = jobKeys;
                        if (newJob) {
                            this.mainService.sendAlert(1);
                        }
                    }
                    );
                }
                
                */

    getSubJobBehSubj(): BehaviorSubject<AngularFirestoreCollection<ISubJob>> {
        return this.subJobCollectionBehSubj;
    }


    getSubJobArrayBehSubj(): BehaviorSubject<ISubJobObject> {
        return this.subJobArrayBehSubj;
    }

    updateSubJob(subJobData: SubJob) {
        const promise = new Promise((resolve, reject) => {
            if (subJobData.key) {
                const userRef: AngularFirestoreDocument<any> = this.fsService.getAngDoc('subJobs', subJobData.key);


                userRef.set(subJobData.getDataAsISubJob(), { merge: true }).then((something) => {
                    console.log('updated data: ', something)
                    resolve(null);
                })

            } else {




                this.subJobCollection.ref.add(subJobData.getDataAsISubJob())
                    .then((res) => {
                        console.log('dataService: createOne success: res: ', res);
                        resolve(res);
                    }).catch(err => {
                        console.error('dataService: createOne: error: ', err);
                        reject(err);
                    });
            }
        })

        return promise;
    }




}