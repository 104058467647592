import { IRegister } from '../core/services/auth.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { AngFireStoreService } from '../core/services/afs.service';
import { ISchool } from "../core/interfaces";
import { Observable } from 'rxjs';


@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

    @ViewChild('snackBar') el: ElementRef;

    mode: string = 'login';

    login: { email: string, pw: string } = { email: '', pw: '' };
    register: IRegister = { email: '', pw: '', pw2: '', fName: '', lName: '', phone: 0, school: '' };
    loginDisabled: boolean = true;
    registerDisabled: boolean = true;
    loginError: number = 0;
    passwordStrength: number = 0;
    needVerification: boolean = false;
    returnPage: string = '';
    schools: Observable<ISchool[]>;
    schoolArray: ISchool[];
    schoolsArray2: ISchool[];
    schoolListLength = 0;
    stateArray: string[];
    cityArray: string[];
    citySchools: ISchool[];
    selectedState: string = null;
    selectedCity: string = null;
    selectedSchool: ISchool = null;
    closeResult: string;
    stateSelectValue: string = null;
    citySelectValue: string = null;
    schoolSelectValue: string = null;
    needEmail: boolean = false;

    constructor(private auth: AuthService, private route: ActivatedRoute, private router: Router, private afs: AngFireStoreService) { }



    ngOnInit() {
        this.auth.isUser.subscribe((isUser) => {
            if (isUser) {
                this.router.navigate(['/tabs/profile'])
            };

        });





        this.returnPage = this.route.snapshot.params['page'];
        if (this.route.snapshot.params['unVerified'] && this.route.snapshot.params['unVerified'] == 'true') {
            this.needVerification = true;

        }
        this.schools = this.afs.getCollection('schools', ref => ref.orderBy('State').orderBy('city').orderBy('name')).snapshotChanges().pipe(map(
            changes => {
                return changes.map(
                    a => {
                        const data = a.payload.doc.data() as ISchool;
                        data.key = a.payload.doc.id;
                        return data;
                    });

            }));
        this.schools.subscribe((data) => {
            this.schoolArray = data;
            this.schoolListLength = data.length;
            this.setStateArray();
        })

    }
    private compare(a: ISchool, b: ISchool) {
        if (a.name < b.name)
            return -1;
        if (a.name > b.name)
            return 1;
        return 0;
    }

    // async getSchoolList() {
    //   let buttonArray = []
    //   this.citySchools.forEach((school) => {
    //     let newObj = {
    //       text: school.name,
    //       icon: 'business',
    //       handler: () => {
    //         console.log('School clicked', school.key);
    //         this.selectedSchool = school;
    //         this.register.school = school.key
    //       }
    //     }
    //     buttonArray.push(newObj)
    //   });
    //   buttonArray.push({
    //     text: 'Cancel',
    //     icon: 'close',
    //     role: 'cancel',
    //     handler: () => {
    //       console.log('Cancel clicked');
    //     }
    //   });


    // }

    setStateArray() {
        if (this.schoolArray) {
            let newArray = [];
            this.schoolArray.forEach((school) => {
                if (newArray.indexOf(school.State) < 0) {
                    newArray.push(school.State)
                }
            });
            this.stateArray = newArray.sort();
            if (newArray.length == 1) {
                this.setCityArray(newArray[0]);
            } else {
                this.cityArray = [];
            }
        }

    }
    setCityArray(state: string) {
        this.selectedState = state;
        if (this.schoolArray && this.stateArray) {
            let newArray = [];
            this.schoolArray.forEach((school) => {
                if (newArray.indexOf(school.city) < 0 && school.State == state) {
                    newArray.push(school.city)
                }
            });
            this.cityArray = newArray.sort();
            if (newArray.length == 1) {
                this.setSchoolArray(newArray[0]);
            } else {
                this.citySchools = [];
            }
        }
    }
    setSchoolArray(city: string) {
        this.selectedCity = city;
        if (this.schoolArray && this.stateArray && this.cityArray) {
            let newArray: ISchool[] = [];
            this.schoolArray.forEach((school) => {
                if (school.State == this.selectedState && school.city == city) {
                    newArray.push(school)
                }
            });
            this.citySchools = newArray.sort(this.compare);
            if (newArray.length == 1) {
                this.register.school = newArray[0].key;
            }

        }
    }
    selectSchool(school: string) {
        this.register.school = school
    }
    // var groupBy = function (xs, key) {
    //   return xs.reduce(function (rv, x) {
    //     (rv[x[key]] = rv[x[key]] || []).push(x);
    //     return rv;
    //   }, {});
    // };

    // console.log(groupBy(['one', 'two', 'three'], 'length'));








    async sendLogin() {

        const getlogin: Promise<number> = this.auth.signInEMail(this.login.email, this.login.pw, this.returnPage);
        getlogin.then((val) => {
            console.log(`waited for login: `, val);
            // if (this.returnPage)
            //     this.router.navigate([this.returnPage]);
            if (val == 1) {
                // this.router.navigate(['tabs/myJobs']);
            }
            this.router.navigate(['/tabs/myjobs']);
        })




    }

    customActionSheetOptionsSchool: any = {
        header: 'Select School',
        subHeader: 'You may change in your profile.'
    };

    customActionSheetOptionsCity: any = {
        header: 'Select city to limit schools to'
    };
    customActionSheetOptionsState: any = {
        header: 'Select State to limit cities to:'
    };
    getError() {
        return this.auth.errorMsg;
    }
    getErrorCode() {
        return this.auth.errorCode;
    }
    sendRegister() {
        this.auth.createAccount(this.register);
        this.mode = 'login';
        this.needVerification = true;
    }
    checkRegisterStatus() {
        if (this.register.email && this.register.email.split('@').length == 2 && this.register.email.split('.').length >= 2 && this.passwordStrength >= 40 && this.register.pw == this.register.pw2 && this.register.school.length > 0) {
            return false
        }
        return true;
    }
    checkPWStrength(pws: number) {
        this.passwordStrength = pws;
        if (pws > 40) {
            this.registerDisabled = false;
        }
    }
    checkLoginStatus() {
        if (this.login.email && this.login.email.split('@').length == 2 && this.login.email.split('.').length >= 2 && this.login.pw.length > 5) {
            this.loginDisabled = false
        }
    }
    resetPW() {



        if (this.login.email.length > 5 && this.login.email.split('@').length > 1 && this.login.email.split('.').length > 1) {
            this.auth.resetPW(this.login.email)
        } else {
            this.needEmail = true;
            setTimeout(() => {
                this.needEmail = false;
            }, 2000)
        }

    }





    setPWStrength(strength: number) {

    }
    resendVerification() {
        console.log(`saending...`);
        this.auth.reSendVerification();
    }
}
